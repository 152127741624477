
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import * as Yup from 'yup';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Actions } from '@/store/enums/StoreEnums';
import { PasswordMeterComponent } from '@/assets/ts/components';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

export default defineComponent({
  name: 'sign-up',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string().required().label('Name'),
      last_name: Yup.string().required().label('Surname'),
      email: Yup.string().min(4).required().email().label('Email'),
      password: Yup.string().required().label('Password'),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .label('Password Confirmation'),
    });

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute('data-kt-indicator', 'on');

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.REGISTER, values)
          .then(() => {
            Swal.fire({
              text: 'You have successfully created new account!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok, got it!',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-primary',
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: 'Dashboard' });
            });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Try again!',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          });

        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
